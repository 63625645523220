import React from "react";

function App() {
  const params = new URLSearchParams(window.location.search);

  const path = params.get("repo");

  const [languageCode, setLanguageCode] = React.useState(
    params.get("lang") ?? "es"
  );

  const [referenceData, setReferenceData] = React.useState<{
    [key: string]: any;
  }>({});

  const [translatedData, setTranslatedData] = React.useState<{
    [key: string]: string;
  }>({});

  React.useEffect(() => {
    fetch(`https://raw.githubusercontent.com/${path}/main/lib/l10n/app_en.arb`)
      .then((response) => response.json())
      .then((data) => setReferenceData(data));
  }, [path]);

  React.useEffect(() => {
    fetch(
      `https://raw.githubusercontent.com/${path}/main/lib/l10n/app_${languageCode}.arb`
    )
      .then((response) => response.json())
      .then((data) => setTranslatedData(data))
      .catch(() => setTranslatedData({}));
  }, [path, languageCode]);

  const keys = Object.keys(referenceData).filter((key) => !key.startsWith("@"));

  return (
    <div>
      <h1>{path}</h1>
      <h3>
        <div>
          Use the two letter language code of the language you are translating into
        </div>
      </h3>
      <div>
        Translating into:{" "}
        <input
          value={languageCode}
          onChange={(e) => setLanguageCode(e.target.value)}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>Key</th>
            <th>Example (en)</th>
          </tr>
          <tr>
            <th>Description</th>
            <th>Translation</th>
          </tr>
        </thead>
        <tbody>
          {keys.map((key) => (
            <>
              <tr key={key}>
                <td style={{ fontWeight: "bold", textAlign: "right" }}>
                  {key}
                </td>
                <td>{referenceData[key]}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>
                  {referenceData["@" + key]?.description ?? ""}
                </td>
                <td>
                  <input
                    style={{ width: "80%" }}
                    value={translatedData[key] ?? ""}
                    onChange={(e) =>
                      setTranslatedData({
                        ...translatedData,
                        [key]: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr style={{ height: "20px" }}></tr>
            </>
          ))}
        </tbody>
      </table>
      <div>
        <h2>
          Done? Click to
          <button
            onClick={() => {
              const content = JSON.stringify(translatedData, null, 2);
              const body = `Please review the following ${languageCode} translation and merge it into the repository.\n\n\`\`\`\n${content}\n\`\`\``;
              // copy to clipboard
              navigator.clipboard.writeText(body);
            }}
          >
            copy
          </button>{" "}
          <div>
            Then send it to muxfd or sputnikplop on <a href="https://discord.gg/UKHJMQs74u">discord</a> or open a PR on GitHub.
          </div>
        </h2>
      </div>
    </div>
  );
}

export default App;
